import {Fragment} from "react";
import {ProgressBar} from "react-bootstrap";

interface ICrawlingItem {
    numeric: number | string;
    type?: string;
    info: string;
}

const CrawlingItem = (props:ICrawlingItem) => {
    const {numeric, type, info} = props;
    return (
        <Fragment>
            <div className={"widget-content"}>
                <div className={"widget-content-outer"}>
                        {type === "progress" ?
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left pe-2 fsize-1">
                                    <div className="widget-numbers fsize-3 text-info">
                                        {numeric}%
                                    </div>
                                </div>
                                <div className="widget-content-right w-100">
                                    <ProgressBar
                                        className="progress-bar-xs"
                                        color="info"
                                        now={Number(numeric)}/>
                                </div>
                            </div>
                            :
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left">
                                    <div className="widget-numbers fsize-3 text-muted">{numeric}</div>
                                </div>
                            </div>
                        }
                    <div className={"widget-content-left fsize-1"}>
                        <div className={"text-muted opacity-6"}>{info}</div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CrawlingItem;