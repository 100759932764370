import {config} from "@config/config";
import {Route, Routes, useLocation} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import VectorSearch from "@pages/search/vector/VectorSearch";
import Chatbot from "@pages/search/chatbot/Chatbot";
import PageTitle from "@layout/main/PageTitle";
import {NavItemProps, SearchNavItem} from "@layout/nav/NavItem";

const SearchPage = () => {
    const {pathname} = useLocation();
    const [heading, setHeading] = useState("");
    const [subheading, setSubheading] = useState("");
    const [icon, setIcon] = useState("");

    useEffect(() => {
        const navItem: NavItemProps | undefined = SearchNavItem.find(item => item.to !== undefined ?
            pathname.includes(item.to) :
            item.content?.some(subItem => pathname.includes(subItem.to)));
        setHeading(navItem?.heading || "");
        setSubheading(navItem?.subheading || "");
        setIcon(navItem?.icon || "");
    }, [pathname]);
    return (
        <Fragment>
            {config.serverType === "solution" || config.serverType === "prod" ?
                <PageTitle heading={heading}
                           subheading={subheading}
                           icon={icon}/>: null}
            <Routes>
                <Route path={"vector/*"} element={<VectorSearch/>}/>
                <Route path={"chatbot/*"} element={<Chatbot/>}/>
            </Routes>
        </Fragment>
    )
}

export default SearchPage;