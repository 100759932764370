import React, {Fragment, useCallback} from "react";
import {Button} from "react-bootstrap";
import {Slide, toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
interface TitleComponentProps {
    message?: string
}
const TitleComponent = ({message}: TitleComponentProps) => {
    const notify22 = useCallback(() => {
        toast(message||"본 사이트는 로이드케이의 자산입니다.", {
           transition: Slide,
           closeButton: true,
           autoClose: 5000,
           position: "bottom-center",
           type: "default"
        });
    }, [message])
    return (
        <Fragment>
            <Button className={"btn-pill btn-shadow me-3"}
                    onClick={notify22}
                    color={"info"}
                    id={"Tooltip-123"}>
                <FontAwesomeIcon icon={faUsers}/>
            </Button>
            {/*<UncontrolledTooltip placement={"left"} target={'Tooltip-123'}>
                Contributors
            </UncontrolledTooltip>*/}
        </Fragment>
    )
}

export default TitleComponent;