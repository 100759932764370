import {ChangeEvent, Fragment, useCallback, useEffect, useState} from "react";
import {Button, Card, CardBody, Col, FloatingLabel, Form, Modal, ModalFooter, Row} from "react-bootstrap";
import {checkUser, createUser} from "@apis/api/management";
import Confirm from "@layout/popup/Confirm";

interface MemberCreateProps {
    showModel: boolean;
    setShowModel: (value: boolean) => void;
    setReload: (value: boolean) => void;
}

const MemberCreate = (props: MemberCreateProps) => {
    const {showModel, setShowModel, setReload} = props;
    const [confirm, setConfirm] = useState(false);
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userAuth, setUserAuth] = useState(false);
    const [checkUserId, setCheckUserId] = useState(true);

    //오류메시지 상태저장
    const [userIdMessage, setUserIdMessage] = useState("");
    const [passwordMessage, setPasswordMessage] = useState("");
    const [passwordConfirmMessage, setPasswordConfirmMessage] = useState("");

    //유효성 검사
    const [isPassword, setIsPassword] = useState(false);
    const [isPasswordConfirm, setIsPasswordConfirm] = useState(false);

    const [nestedModal, setNestedModal] = useState(false);
    const toggle = () => {
        if(showModel){
            setUserId("");
            setUserName("");
            setUserEmail("");
            setPassword("");
            setConfirmPassword("");
            setConfirm(false);
            setIsPassword(false);
            setIsPasswordConfirm(false);
            setUserAuth(false);
        }
        setShowModel(!showModel);
    }

    const controlNestedModal = async () => {
        if (await checkUser(userId)){
            setUserIdMessage("동일한 ID가 있습니다. 확인해주시기 바랍니다.");
            setCheckUserId(false);
            return false;
        }else{
            setUserIdMessage("");
            setCheckUserId(true);
            return true;
        }
    }

    const create = useCallback(async () => {
        if (confirm){
            await createUser({
                email: userEmail,
                name: userName,
                password: password,
                perm_manager: userAuth,
                username: userId
            }).then((response) => {
                if (response){
                    setReload(true);
                }
            });
        }
    },[confirm]);

    useEffect(() => {
        const passwordRegex =  /(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-]).{8,25}/;
        if (!passwordRegex.test(password)) {
            setPasswordMessage('숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요.');
            setIsPassword(false);
        }else {
            setPasswordMessage('');
            setIsPassword(true);
        }
    }, [password]);

    useEffect(() => {
        if (password === confirmPassword) {
            setPasswordConfirmMessage('비밀번호가 일치합니다.');
            setIsPasswordConfirm(true);
        }else{
            setPasswordConfirmMessage('비밀번호가 일치하지 않습니다.');
            setIsPasswordConfirm(false);
        }
    }, [confirmPassword]);
    return (
        <Fragment>
            <Modal show={showModel} onHide={toggle}>
                <Modal.Body>
                    <Card className={"mb-5"}>
                        <CardBody>
                            <Form>
                                <Form.Group>
                                    <FloatingLabel label={"ID"} className={"mb-3"}>
                                        <Form.Control type={"text"}
                                                      id={"userId"}
                                                      name={"userId"}
                                                      onChange={(e:ChangeEvent<HTMLInputElement>) => setUserId(e.target.value)}/>
                                    </FloatingLabel>
                                    <FloatingLabel label={"Name"} className={"mb-3"}>
                                        <Form.Control type={"text"}
                                                      id={"username"}
                                                      name={"username"}
                                                      onChange={(e:ChangeEvent<HTMLInputElement>) => setUserName(e.target.value)}/>
                                    </FloatingLabel>
                                    <FloatingLabel label={"Email"} className={"mb-3"}>
                                        <Form.Control type={"email"}
                                                      id={"email"}
                                                      name={"email"}
                                                      onChange={(e:ChangeEvent<HTMLInputElement>) => setUserEmail(e.target.value)}/>
                                    </FloatingLabel>
                                    <FloatingLabel label={"Password"} className={"mb-3"}>
                                        <Form.Control type={"password"}
                                                      id={"password"}
                                                      name={"password"}
                                                      onChange={(e:ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}/>
                                        <Form.Text className={`message ${isPassword ? 'success' : 'error'}`}>{passwordMessage}</Form.Text>
                                    </FloatingLabel>
                                    <FloatingLabel label={"Confirm Password"} className={"mb-3"}>
                                        <Form.Control type={"password"}
                                                      id={"confirmPassword"}
                                                      name={"confirmPassword"}
                                                      onChange={(e:ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}/>
                                        <Form.Text className={`message ${isPasswordConfirm ? 'success' : 'error'}`}>{passwordConfirmMessage}</Form.Text>
                                    </FloatingLabel>
                                </Form.Group>
                                <Row className={"row-cols-lg-auto g-3 align-items-center"}>
                                    <Col>
                                        <Form.Check type={"radio"}
                                                    id={"manager"}
                                                    name={"userAuth"}
                                                    label={"관리자"}
                                                    value={"true"}
                                                    onChange={(e:ChangeEvent<HTMLInputElement>) => setUserAuth(e.target.checked)}/>
                                    </Col>
                                    <Col>
                                        <Form.Check type={"radio"}
                                                    id={"member"}
                                                    name={"userAuth"}
                                                    label={"회원"}
                                                    value={"false"}
                                                    onChange={(e:ChangeEvent<HTMLInputElement>) => setUserAuth(e.target.checked)}/>
                                    </Col>
                                </Row>
                                <Form.Text className={`message ${checkUserId ? 'success' : 'error'}`}>{userIdMessage}</Form.Text>
                            </Form>
                        </CardBody>
                    </Card>
                </Modal.Body>
                <Confirm message={"생성하시겠습니까?"}
                         setConfirm={setConfirm}
                         nestedModal={nestedModal}
                         setNestedModal={controlNestedModal}/>
                <ModalFooter>
                    <Button color={"primary"}
                            onClick={controlNestedModal}
                            disabled={!(userId.length > 0 && userName.length > 0 && userEmail.length > 0 && isPassword && isPasswordConfirm)}>
                        생성
                    </Button>
                    <Button color={"secondary"} onClick={toggle}>취소</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default MemberCreate;