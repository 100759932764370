import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {selectTheme, themeActions} from "../../hooks/themeOptions";
import {Button, ListGroup, ListGroupItem} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons"
import PerfectScrollbar from "react-perfect-scrollbar";
import cx from "classnames";

const Theme = () => {
    const themeOptions = useAppSelector(selectTheme);
    const dispatch = useAppDispatch();

    const [showing, setShowing] = useState(false);
    const toggleEnableFixedHeader = () => {
        dispatch(themeActions.setEnableFixedHeader(!themeOptions.enableFixedHeader));
    }


    return (
        <div className={"ui-theme-settings " + (showing ? 'settings-open' : '')} style={{display:'none'}}>
            <Button className="btn-open-options" id="TooltipDemo" color="warning" onClick={() => setShowing(!showing)}>
                <FontAwesomeIcon icon={faCog} spin  color="#573a04" fixedWidth={false} size="2x"/>
            </Button>
            {/*<UncontrolledTooltip placement="left" target={'TooltipDemo'}>
                Open Layout Configurator
            </UncontrolledTooltip>*/}
            <div className="theme-settings__inner">
                <PerfectScrollbar>
                    <div className="theme-settings__options-wrapper">
                        <h3 className="themeoptions-heading"> Layout Options</h3>
                        <div className="p-3">
                            <ListGroup>
                                <ListGroupItem>
                                    <div className="widget-content p-0">
                                        <div className="widget-content-wrapper">
                                            <div className="widget-content-left me-3">
                                                <div className="switch has-switch"
                                                     data-on-label="ON"
                                                     data-off-label="OFF"
                                                     onClick={toggleEnableFixedHeader}>
                                                    <div className={cx("switch-animate", {
                                                        'switch-on': true,
                                                        'switch-off': false})}>
                                                        <input type="checkbox"/>
                                                        <span className="switch-left bg-success">ON</span>
                                                        <label>&nbsp;</label>
                                                        <span className="switch-right bg-success">OFF</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"widget-content-left"}>
                                                <div className={"widget-heading"}>
                                                    Fixed Header
                                                </div>
                                                <div className={"widget-subheading"}>
                                                    Makes the header top fixed, always visible!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
    )
}

export default Theme;