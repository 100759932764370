import {Fragment, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {selectTheme, themeActions} from "../../hooks/themeOptions";
import Hamburger from "hamburger-react";
import {Button} from "react-bootstrap";
import cx from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const MobileMenu = () => {
    const {
        enableMobileMenu,
        enableMobileMenuSmall
    } = useAppSelector(selectTheme);
    const dispatch = useAppDispatch();
    const [secondaryMenuMobile, setSecondaryMenuMobile] = useState(false);

    const toggleMobileSidebar = () => {
        dispatch(themeActions.setEnableMobileMenu(!enableMobileMenu));
    }

    const toggleMobileSmall = () => {
        dispatch(themeActions.setEnableMobileMenuSmall(!enableMobileMenuSmall));
    }

    return (
        <Fragment>
            <div className={"app-header__mobile-menu"}>
                <Hamburger color={"#6c757d"} toggled={enableMobileMenu} toggle={toggleMobileSidebar}/>
            </div>
            <div className={"app-header__menu"}>
                <span onClick={toggleMobileSmall}>
                    <Button size={"sm"}
                            className={cx("btn-icon btn-icon-only",{active: secondaryMenuMobile})}
                            color={"primary"}
                            onClick={() => setSecondaryMenuMobile(!secondaryMenuMobile)}>
                        <div className={"btn-icon-wrapper"}>
                            <FontAwesomeIcon icon={faEllipsisV}/>
                        </div>
                    </Button>
                </span>
            </div>
        </Fragment>
    )
}

export default MobileMenu;