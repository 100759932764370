import axios from "axios";
import Cookies from "js-cookie";

const baseAPI = (url: string, options?: any) => {
    return axios.create({ baseURL: url, ...options})
}

const authAPI = (url: string, options?: any) => {
    const token = Cookies.get("jwt");
    return axios.create({
        baseURL: url,
        headers: {
            Authorization: `bearer ${token}`,
        },
        ...options,
    });
}

export const baseInstance = baseAPI("/api");
export const authInstance = authAPI("/api");