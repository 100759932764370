import {Fragment, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import ChatTextArea from "@pages/search/chatbot/component/ChatTextArea";
import {ConversationProps, searchResponse} from "@apis/api/search";
import InputTextArea from "@pages/search/chatbot/component/InputTextArea";
import PdfReader from "@pages/search/chatbot/pdf/component/PdfReader";
import PdfFileList from "@pages/search/chatbot/pdf/component/PdfFileList";
import {selectedFile} from "@layout/foldertree/FolderTree";
import {config} from "@config/config";

const TermsPdf = () => {
    const [conversations, setConversations] = useState<ConversationProps[]>([]);
    const [selectedPage, setSelectedPage] = useState<{ fileName: string, page: number } | null>(null);
    const [selectedFile, setSelectedFile] = useState<selectedFile>({name: undefined, fileName: undefined});
    const [page, setPage] = useState<number>(0);
    const [pdf, setPdf] = useState<string>("");
    const [keywords, setKeywords] = useState<string[]>([]);
    const [size, setSize] = useState<number>(5);
    const env = config.serverType;

    useEffect(() => {
        if (env === "lh"){
            setSize(6);
        }
    }, []);
    useEffect(() => {
        if (selectedFile.fileName === undefined){
            setPdf("");
        }else {
            setPdf("/api/vector/download?file_name=" + selectedFile.fileName);
        }
    },[selectedFile.fileName]);

    return (
        <Fragment>
            <Row>
                {config.serverType === "shinhwa" ?
                    <Col xs={2} md={2} style={{ display: 'flex', flexDirection: 'column'}}>
                        <PdfFileList selectedFile={selectedFile}
                                     setSelectedFile={setSelectedFile}/>
                    </Col> : null
                }
                <Col xs={7} md={7} style={{ display: 'flex', flexDirection: 'column' }}>
                    <ChatTextArea conversations={conversations}
                                  selectedPage={selectedPage}
                                  setSelectedPage={setSelectedPage}
                                  setSelectedFile={setSelectedFile}
                                  setPage={setPage}
                                  keywords={keywords}
                                  setKeywords={setKeywords}/>
                    <InputTextArea conversations={conversations}
                                   setSelectedPage={setSelectedPage}
                                   setFileName={setSelectedFile}
                                   setKeywords={setKeywords}
                                   setConversations={setConversations}
                                   searchFunction={searchResponse}
                                   fileName={selectedFile.fileName}
                                   originalFileName={selectedFile.name}/>
                </Col>
                <Col xs={5} md={5} className={"col-5"} style={{ display: 'flex', flexDirection: 'column' }}>
                    <PdfReader path={pdf} page={page} keywords={keywords}/>
                </Col>
            </Row>
        </Fragment>
    )
}

export default TermsPdf;
