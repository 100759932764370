import Styled from 'styled-components';
import  {FcFolder, FcFile } from "react-icons/fc";

interface RowProps {
    type: 'folder' | 'file';
    selected: boolean;
}
const Row = Styled.div<RowProps>`
    display:flex;
    align-items:center;
    cursor:${({type}) => type === 'folder' ? 'pointer' : 'default'};
    padding: 0rem;
    margin: 0rem;
    height: 2rem;
    background-color: ${({ selected }) => (selected ? '#007bff' : 'transparent')};
    color: ${({ selected }) => (selected ? '#fff' : 'inherit')};
    border-left: ${({ selected }) => (selected ? '4px solid #007bff' : 'none')};
    `
const Container = Styled.div`
    position: relative;
    display: inline-block;
    max-width: 200px; /* 원하는 최대 너비를 설정하세요 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
`;

const Tooltip = Styled.div`
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* 위에 보여지도록 설정 */
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    max-width: 300px;
    white-space: normal;

    /* Tooltip arrow */
    &::after {
        content: '';
        position: absolute;
        top: 100%; /* Arrow bottom 위치 */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }
`;

const EllipsisText = Styled.span`
    &:hover + ${Tooltip} {
        visibility: visible;
    }
`;

interface RowContainerProps {
    type: 'folder' | 'file';
    name: string;
    handleClick?: () => void;
    isSelected?: boolean;
}
const RowContainer = ({ type, name, handleClick, isSelected = false} : RowContainerProps) => {
    return (
        <Row type={type} onClick={handleClick ? handleClick : undefined} selected={isSelected}>
            {type === 'folder' ? <FcFolder/> : <FcFile/>}
            <Container>
                <EllipsisText>{name}</EllipsisText>
                <Tooltip className={"tooltip"}>{name}</Tooltip>
            </Container>
        </Row>
    )
}

export default RowContainer;