import {Fragment} from "react";
import {Link, useLocation} from "react-router-dom";
import MetisMenu from "@metismenu/react";

interface NavItemProps {
    title?: string;
    icon?: string;
    to: string;
}
const VerticalNav = (props: NavItemProps) => {
    const {pathname} = useLocation();
    let active = "";
    if (props.to === pathname) {
        active = "active";
    }
    return (
        <Fragment>
            <MetisMenu className={"vertical-nav-menu"} subMenu={"div"}>
                <ul className={"metismenu-container"}>
                    <li className={"metismenu-item"}>
                        <i className={"metismenu-icon " + props.icon}/>
                        <Link className={"metismenu-link " + active} to={props.to}>
                            {props.title}
                        </Link>
                    </li>
                </ul>
            </MetisMenu>
        </Fragment>
    )
}

export default VerticalNav;