import {Dispatch, Fragment, SetStateAction, useState} from "react";
import {json} from "@pages/search/chatbot/pdf/component/data";
import FolderTree, {selectedFile} from "@layout/foldertree/FolderTree";
import Styled from "styled-components";

const StyledContainer = Styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 2rem 0;
    border-radius: 20px;
    overflow-y: auto;
`
interface PdfFileListProps {
    selectedFile: selectedFile;
    setSelectedFile: Dispatch<SetStateAction<selectedFile>>;
}

const PdfFileList = ({selectedFile, setSelectedFile}: PdfFileListProps) => {

    return (
        <Fragment>
            <StyledContainer>
                <FolderTree json={json}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}/>
            </StyledContainer>
        </Fragment>
    )
}

export default PdfFileList;