import {Fragment} from "react";
import {Card, CardBody, CardHeader} from "react-bootstrap";
import CountUp from "react-countup";
import {Area, AreaChart, ResponsiveContainer, Tooltip} from "recharts";

const data3 = [
    { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
    { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
    { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
    { name: "Page D", uv: 4780, pv: 7908, amt: 2000 },
    { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
    { name: "Page F", uv: 1390, pv: 3800, amt: 1500 },
    { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
    { name: "Page E", uv: 2890, pv: 9800, amt: 2181 },
    { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
    { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
    { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
    { name: "Page C", uv: 2000, pv: 6800, amt: 2290 },
];

const OpenAIStatus = () => {
    return (
        <Fragment>
            <Card className="mb-3">
                <CardHeader className="card-header-tab">
                    <div className="card-header-title">
                        <i className="header-icon lnr-laptop-phone icon-gradient bg-tempting-azure">
                            {" "}
                        </i>
                        Open AI API 사용 현황
                    </div>
                </CardHeader>
                <CardBody className="pt-2 pb-0">
                    <div className="widget-chart widget-chart2 text-start pt-0">
                        <div className="widget-chat-wrapper-outer">
                            <div className="widget-chart-content">
                                <div className="widget-chart-flex">
                                    <div className="widget-numbers">
                                        <div className="widget-chart-flex">
                                            <div className="text-success">
                                                <CountUp start={0}
                                                         end={348}
                                                         separator=""
                                                         decimals={0}
                                                         decimal=","
                                                         prefix=""
                                                         duration={20}/>
                                                /day
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="widget-chart-wrapper widget-chart-wrapper-lg he-auto opacity-10 m-0">
                                <ResponsiveContainer height={131}>
                                    <AreaChart data={data3} margin={{top: -20, right: 0, left: 0, bottom: 0,}}>
                                        <Tooltip />
                                        <Area type="monotoneX"
                                              dataKey="uv"
                                              stroke="#3ac47d"
                                              strokeWidth={3}
                                              fill="#3ac47d"
                                              fillOpacity=".1"/>
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default OpenAIStatus;