import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Collapse, Form,
    InputGroup,
    ListGroup, ListGroupItem,
    Row
} from "react-bootstrap";
import React, {Fragment, KeyboardEvent, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {searchVectorNews} from "@apis/api/search";
import DatePicker from "react-widgets/DatePicker";
import {CSSTransition, TransitionGroup} from "react-transition-group";

interface ResultProps {
    url: string;
    title: string;
    content: string;
    utc_date: string;
    press?: string;
    journalists?: string;
}

const News = () => {
    const [keyword, setKeyword] = useState("");
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [searchResults, setSearchResults] = useState<ResultProps[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const nodeRef = useRef(null);
    const handleKeyUp = (e: KeyboardEvent) => {
        e.preventDefault();
        Search();
    }

    const Search = () => {
        setIsLoading(true);
        searchVectorNews({
            searchKeyword: keyword,
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
            title: title,
            content: content
        })
            .then((response) => {
                if (response?.status === 200) {
                    setSearchResults(response.data.slice(0, 3));
                }
            });
        setIsLoading(false);
    }

    const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
    }

    const toggleCollapse = () => setCollapse(!collapse);

    const renderResults = (results: ResultProps[]) => (
        <ListGroup>
            {results.map((result:ResultProps, index:number) => (
                <ListGroupItem key={index} style={{padding: "1rem"}}>
                    <a href={result.url}
                       target={"_blank"}
                       rel={"noopener noreferrer"}
                       style={{
                           fontWeight: "bold"
                       }}>
                        {result.title}
                    </a>
                    <p style={{
                        margin: "0.5rem 0",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical"
                    }}>
                        {result.content}
                    </p>
                    <p style={{color: "grey", fontSize: "0.8rem", margin: "0"}}>
                        {result.press} | {result.journalists} |{" "}
                        {new Date(result.utc_date).toLocaleDateString()}
                    </p>
                </ListGroupItem>
            ))}
        </ListGroup>
    )
    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component="div"
                               nodeRef={nodeRef}
                               classNames={"TabsAnimation"}
                               appear={true}
                               timeout={1500}
                               enter={true}
                               exit={false}>
                    <div>
                        <Row>
                            <Col lg="2" sm={0}/>
                            <Col lg="8" sm="12">
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <CardTitle>검색문 입력</CardTitle>
                                        <InputGroup>
                                            <Form.Control placeholder={"텍스트 입력"}
                                                          value={keyword}
                                                          onChange={handleKeywordChange}
                                                          onKeyUp={(e) => {
                                                              if (e.key === "Enter") {
                                                                  handleKeyUp(e);
                                                              }
                                                          }}/>
                                            <Button color="secondary" onClick={Search}>
                                                {isLoading ? (
                                                    <FontAwesomeIcon
                                                        style={{ color: "white" }}
                                                        spin
                                                        icon={faSpinner}
                                                    />
                                                ) : (
                                                    "검색"
                                                )}
                                            </Button>
                                        </InputGroup>
                                        <br/>
                                        <Button onClick={toggleCollapse}>상세검색</Button>
                                        <Collapse in={collapse}>
                                            <div>
                                                <InputGroup>
                                                    <InputGroup.Text>날짜</InputGroup.Text>
                                                    <DatePicker />~<DatePicker/>
                                                </InputGroup>
                                                <InputGroup>
                                                    <InputGroup.Text>제목</InputGroup.Text>
                                                    <Form.Control placeholder={"제목 입력"}/>
                                                </InputGroup>
                                                <InputGroup>
                                                    <InputGroup.Text>내용</InputGroup.Text>
                                                    <Form.Control placeholder={"내용 입력"}/>
                                                </InputGroup>
                                                <Button className="btn" onClick={() => Search()}>
                                                    <FontAwesomeIcon icon={faSearch}/>
                                                </Button>
                                            </div>
                                        </Collapse>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="2" sm={0}/>
                            <Col lg={8} sm={12}>
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <CardTitle>Result</CardTitle>
                                        {renderResults(searchResults)}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default News;