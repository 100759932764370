import {Fragment} from "react";
import {Card, CardBody, CardHeader, Col, Row} from "react-bootstrap";
import {Area, AreaChart, ResponsiveContainer, Tooltip} from "recharts";
import CrawlingItem from "@pages/dashboard/component/CrawlingItem";

const data22 = [
    { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
    { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
    { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
    { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
    { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
    { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
    { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
    { name: "Page C", uv: 2000, pv: 6800, amt: 2290 },
    { name: "Page D", uv: 4780, pv: 7908, amt: 2000 },
    { name: "Page E", uv: 2890, pv: 9800, amt: 2181 },
    { name: "Page F", uv: 1390, pv: 3800, amt: 1500 },
    { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
];

const CrawlingStatus = () => {
    return (
        <Fragment>
            <Card className={"mb-3"}>
                <CardHeader className={"card-header-tab"}>
                    <div className={"card-header-title"}>
                        <i className={"header-icon lnr-rocket icon-gradient bg-tempting-azure"}>
                            {" "}
                        </i>
                        실시간 크롤링 현황
                    </div>
                </CardHeader>
                <CardBody className={"pt-2 pb-0"}>
                    <Row>
                        <Col md={6}>
                            <CrawlingItem numeric={"18,704"} info={"Succeeded (Today)"}/>
                        </Col>
                        <Col md={6}>
                            <CrawlingItem numeric={702} info={"Failed (Today)"}/>
                        </Col>
                        <Col md={6}>
                            <CrawlingItem numeric={61} type={"progress"} info={"News Data"}/>
                        </Col>
                        <Col md="6">
                            <CrawlingItem numeric={39} type={"progress"} info={"Other Documents"}/>
                        </Col>
                    </Row>
                </CardBody>
                <div className="widget-chart p-0">
                    <ResponsiveContainer height={223}>
                        <AreaChart data={data22}
                                   margin={{top: -25, right: 0, left: 0, bottom: 0}}>
                            <defs>
                                <linearGradient id="colorPv2"
                                                x1="0" y1="0"
                                                x2="0" y2="1">
                                    <stop offset="10%"
                                          stopColor="#f7b924"
                                          stopOpacity={0.7}/>
                                    <stop offset="90%"
                                          stopColor="#f7b924"
                                          stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <Tooltip/>
                            <Area type="monotoneX"
                                  dataKey="uv"
                                  stroke="#f7b924"
                                  strokeWidth={2}
                                  fillOpacity={1}
                                  fill="url(#colorPv2)"/>
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </Card>
        </Fragment>
    )
}

export default CrawlingStatus;