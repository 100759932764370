
export interface SliderContentProps {
    styleType: string,
    bgImg: string,
    title: string,
    content: string,
}
function SliderContent({styleType, bgImg, title, content}: SliderContentProps){
    return (
        <div className={`h-100 d-flex justify-content-center align-items-center ${styleType}`}>
            <div className="slide-img-bg" style={{backgroundImage: `url(${bgImg})`}}/>
            <div className="slide-content">
                <h3>{title}</h3>
                <p>{content}</p>
            </div>
        </div>
    )
}

export default SliderContent;