import {Fragment, useRef} from "react";
import PageTitle from "@layout/main/PageTitle";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import {Col, Row} from "react-bootstrap";
import DataStatus from "@pages/dashboard/component/DataStatus";
import CrawlingStatus from "@pages/dashboard/component/CrawlingStatus";
import OpenAIStatus from "@pages/dashboard/component/OpenAIStatus";
import Reference from "@pages/dashboard/component/Reference";


const Dashboard = () => {
    const nodeRef = useRef(null);
    return (
        <Fragment>
            <PageTitle heading={"포탈 대시보드"}
                       subheading={"LLOYDK 검색 포탈 메인"}
                       icon={"pe-7s-graph icon-gradient bg-happy-itmeo"}/>
            <TransitionGroup>
                <CSSTransition component={"div"}
                               nodeRef={nodeRef}
                               className={"TabsAnimation"}
                               appear={true}
                               timeout={1500}
                               enter={false}
                               exit={false}>
                    <div>
                        <Row>
                            <Col xs={12} sm={12}>
                                <DataStatus/>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12} xl={6}>
                                <CrawlingStatus/>
                            </Col>
                            <Col lg="12" xl="6">
                                <Row>
                                    <OpenAIStatus/>
                                </Row>
                                <Row>
                                    <Reference/>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default Dashboard;