import axios from "axios";
import Cookies from "js-cookie";

export const getUserList = async (page:number, perPage:number) => {
    return await axios.get(`/api/user/list?page_number=${page}&page_size=${perPage}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("access_token")}`
            }
        }).then(res => {
            if (res.status === 200) {
                return res.data;
            }else{
                return [];
            }
        }
    )
}

export const checkUser = async (userId: string) => {
    return await axios.get(`/api/user/check?username=${userId}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("access_token")}`
            }
        }).then(res => {
            return res.data.result === true;
        }
    )
}


interface UserCreateProps {
    email: string;
    name: string;
    password: string;
    perm_manager: boolean;
    username: string;
}

export const createUser = async (props: UserCreateProps) => {
    return await axios.post(`/api/user/create`, props,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("access_token")}`
            }
        }).then(res => {
            return res.status === 201;
        }
    )
}