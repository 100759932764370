import {Fragment} from "react";
import FooterDots from "@layout/footer/components/FooterDots";

const Footer = () => {
    return (
        <Fragment>
            <div className="app-footer">
                <div className="app-footer__inner">
                    <div className="app-footer-left">
                        <FooterDots />
                    </div>
                    <div className="app-footer-right">
                        Copyright @ LLOYDK 2024
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Footer;