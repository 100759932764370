import {Fragment} from "react";
import {Button, Dropdown, DropdownMenu, DropdownToggle} from "react-bootstrap";
import avatar from "@assets/utils/images/avatars/0.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import city3 from "@assets/utils/images/dropdown-header/city3.jpg";
import {useAuth} from "@layout/auth/AuthContext";

const UserBox = () => {
    //todo
    //cookie auth check and logout
    const {logout} = useAuth();
    return (
        <Fragment>
            <div className={"header-btn-lg pe-0"}>
                <div className={"widget-content p-0"}>
                    <div className={"widget-content-wrapper"}>
                        <div className={"widget-content-left"}>
                            <Dropdown>
                                <DropdownToggle className={"p-0 btn btn-link bg-white border-white"}>
                                    <img width={42} className={"rounded-circle"} src={avatar} alt=""/>
                                    <FontAwesomeIcon icon={faAngleDown} className={"ms-2 opacity-8"} style={{color: "gray"}}/>
                                </DropdownToggle>
                                <DropdownMenu className={"rm-pointers dropdown-menu-lg"}>
                                    <div className={"dropdown-menu-header"}>
                                        <div className={"dropdown-menu-header-inner bg-info"}>
                                            <div className={"menu-header-image opacity-2"} style={{backgroundImage: "url(" + city3 + ")"}}/>
                                            <div className={"menu-header-content text-start"}>
                                                <div className={"widget-content p-0"}>
                                                    <div className={"widget-content-wrapper"}>
                                                        <div className={"widget-content-left me-3"}>
                                                            <img width={42} className={"rounded-circle"} src={avatar} alt={""}/>
                                                        </div>
                                                        <div className={"widget-content-left"}>
                                                            <div className={"widget-heading"}>
                                                                {'Admin'}
                                                            </div>
                                                            <div className={"widget-subheading opacity-8"}>
                                                                상태메세지가 없습니다.
                                                            </div>
                                                        </div>

                                                        <div className={"widget-content-right me-2"}>
                                                            <Button className={"btn-pill btn-shadow btn-hover-shine"}
                                                                    color={"focus"}
                                                                    onClick={logout}>Logout</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className={"widget-content-left ms-3 header-user-info"}>
                            <div className={"widget-heading"}>{'Admin'}</div>
                            <div className={"widget-subheading"}>환영합니다!</div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UserBox;