import React, {FormEvent, Fragment, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import MainSlider from "@pages/userPages/login/component/Slider";
import {loginUser} from "@apis/api/auth";
import {useNavigate} from "react-router-dom";
import {useAuth} from "@layout/auth/AuthContext";


const LoginPage = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {login} = useAuth();
    const userNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    }
    const passwordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        loginUser({username : username, password: password})
            .then((response) => {
                if(response?.status === 200){
                    const {access_token} = response.data;
                    login(access_token);
                    navigate('/dashboard');
                }else{

                }
            });
    }
    return (
        <Fragment>
            <div className="h-100">
                <Row className="h-100 g-0">
                    <Col lg="4" className="d-none d-lg-block">
                        <MainSlider/>
                    </Col>
                    <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                        <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                            <div className="app-logo" style={{
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                height: "2rem",
                                marginBottom: "1rem"
                            }}/>
                            <h4 className="mb-0">
                                <div>LLOYDK Search Portal Login</div>
                            </h4>
                            <h6 className="mt-3">
                                생성형 AI 검색으로 가는 가장 빠른길, 로이드케이.
                            </h6>
                            <Row className="divider"/>
                            <div>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Username</Form.Label>
                                                <Form.Control type="text" name="username" id="username" placeholder="Username here..." onChange={userNameChange}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password" name="password" id="password" placeholder="Password here..." onChange={passwordChange}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="divider" />
                                    <div className="d-flex align-items-center">
                                        <div className="ms-auto">
                                            <Button color="primary" size="lg" type={"submit"}>
                                                로그인
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )
}
export default LoginPage;