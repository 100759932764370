import {Fragment} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {selectTheme, themeActions} from "../../hooks/themeOptions";
import Hamburger from 'hamburger-react'
import MobileMenu from "@layout/mobile/MobileMenu";

const HeaderLogo = () => {
    const {enableClosedSidebar} = useAppSelector(selectTheme);
    const dispatch = useAppDispatch();
    const toggleEnableClosedSidebar = () => {
        dispatch(themeActions.setEnableClosedSidebar(!enableClosedSidebar));
    }
    return (
        <Fragment>
            <div className={"app-header__logo"}>
                {/*<div className={"logo-src"}/>*/}
                <div className={"header__pane ms-auto"}>
                    <Hamburger toggled={enableClosedSidebar} toggle={toggleEnableClosedSidebar} color={"#6c757d"}/>
                </div>
            </div>
            <MobileMenu/>
        </Fragment>
    )
}

export default HeaderLogo;