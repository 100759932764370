import {Fragment} from "react";
import {Route, Routes} from "react-router-dom";
import ElasticDocsGuide from "@pages/search/chatbot/guide/ElasticDocsGuide";
import TermsPdf from "@pages/search/chatbot/pdf/TermsPdf";

const ChatbotPage = () => {
    return (
        <Fragment>
            <Routes>
                <Route path={"guide"} element={<ElasticDocsGuide/>}/>
                <Route path={"pdf"} element={<TermsPdf/>}/>
            </Routes>
        </Fragment>
    )
}

export default ChatbotPage;