import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import {useAuth} from './AuthContext';
import Cookies from "js-cookie";

const ProtectedRoute = () => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    if (!isAuthenticated) {
        const token = Cookies.get('jwt');
        if (token){
            return <Outlet/>;
        }
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
