import {Fragment} from "react";
import VerticalNav from "@layout/nav/VerticalNav";

const VerticalNavWrapper = () => {

    return (
        <Fragment>
            <h5 className="app-sidebar__heading">Main</h5>
            <VerticalNav title={"포탈 대시보드"} to={"/dashboard"} icon={"pe-7s-graph"}/>
            <h5 className="app-sidebar__heading">SEARCH</h5>
            <VerticalNav title={"자연어 검색"} to={"/search/vector/news"} icon={"pe-7s-graph1"}/>
            <VerticalNav title={"Generative AI"} to={"/search/chatbot/guide"} icon={"pe-7s-glasses"}/>
            <h5 className="app-sidebar__heading">SYSTEM MANAGEMENT</h5>
            <VerticalNav title={"회원 관리"} to={"/management/member"} icon={"pe-7s-users"}/>
            <VerticalNav title={"토큰 관리"} to={"/management/token"} icon={"pe-7s-config"}/>
        </Fragment>
    )
}

export default VerticalNavWrapper;