export interface SubNavItemProps{
    heading: string;
    subheading: string;
    icon: string;
    to: string;
}

export interface NavItemProps{
    icon: string;
    heading: string;
    subheading?: string;
    content?: SubNavItemProps[];
    to?: string;
}

export const MainNavItem: NavItemProps[] = [
    {
        icon: 'pe-7s-graph',
        heading: '포탈 대시보드',
        to: 'dashboard'
    }
]

export const SearchNavItem: NavItemProps[] = [
    {
        icon: 'pe-7s-graph1',
        heading: '자연어 검색',
        subheading: '자연어 기반 백터 검색',
        to: 'search/vector/news'
    },
    {
        icon: 'pe-7s-news-paper',
        heading: 'Generative AI',
        subheading: '사용자 정의 데이터 기반으로 가이드해주는 생성형 AI',
        to: 'search/chatbot/guide'
    }
]

export const ManagementNavItem: NavItemProps[] = [
    {
        icon: 'pe-7s-users',
        heading: '회원 관리',
        to: 'management/member'
    },
    {
        icon: 'pe-7s-target',
        heading: '토큰 관리',
        to: 'management/token'
    }
]