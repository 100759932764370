import {Fragment, Suspense, useEffect, useState} from "react";
import Loader from 'react-loaders';
import {Routes, Route, Navigate} from "react-router-dom"
import LoginPage from "@pages/userPages/login/Login";
import DashBoardMain from "@pages/dashboard/DashBoardMain";
import ProtectedRoute from "@layout/auth/ProtectedRoute";
import {AuthProvider} from "@layout/auth/AuthContext";
import Pages from "@pages/Pages";
import {config} from "@config/config";

function LoaderComponent() {
    return (
        <div className="loader-container">
            <div className="loader-container-inner">
                <div className="text-center">
                    <Loader type="ball-pulse-rise" active/>
                </div>
                <h6 className="mt-5">
                    잠시만 기다려 주세요
                    <small>최상의 검색 결과를 위해 페이지를 조립중입니다.</small>
                </h6>
            </div>
        </div>
    )
}

function securePage() {
    return (
        <Route element={<ProtectedRoute/>}>
            <Route path="/dashboard" element={<DashBoardMain/>}/>
            <Route path={"/*"} element={<Pages/>}/>
        </Route>
    )
}

function AppMain() {
    const [mainPage, setMainPage] = useState("");

    useEffect(() => {
        if (config.serverType === "solution" || config.serverType === "prod"){
            setMainPage("/login");
        }else{
            setMainPage("/search/chatbot/pdf");
        }
    }, [mainPage]);


    return (
        <Fragment>
            <AuthProvider>
                <Suspense fallback={<LoaderComponent/>}>
                    <Routes>
                        <Route path="/" element={<Navigate replace to={mainPage}/>}/>
                        <Route path="/login" element={<LoginPage/>}/>
                        {config.serverType === "solution" || config.serverType === "prod"
                            ? securePage()
                            : <Route path={"/*"} element={<Pages/>}/>}
                    </Routes>
                </Suspense>
            </AuthProvider>
        </Fragment>
    )
}

export default AppMain;