import Slider from "react-slick";
import SliderContent from "@pages/userPages/login/component/SliderContent";
import bg1 from "@assets/utils/images/originals/city.jpg";
import bg3 from "@assets/utils/images/originals/citynights.jpg";
import bg2 from "@assets/utils/images/originals/citydark.jpg";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
}

const MainSlider = () => {
    return (
        <div className="slider-light">
            <Slider {...settings}>
                <SliderContent
                    styleType={"bg-plum-plate"}
                    bgImg={bg1}
                    title={"생성형 AI 시대"}
                    content={"가장 중요한건 사람입니다. 혁신적인 AI 기반 데이터 플랫폼과 함께 도약하세요."}
                />
                <SliderContent
                    styleType={"bg-premium-dark"}
                    bgImg={bg3}
                    title={"로이드케이"}
                    content={"생성형 AI 검색의 가장 든든한 파트너"}
                />
                <SliderContent
                    styleType={"bg-sunny-morning"}
                    bgImg={bg2}
                    title={"You know, For search."}
                    content={"최고 최적 최초"}
                />
            </Slider>
        </div>
    )
}

export default MainSlider;