import {Fragment} from "react";

interface INumericalItem {
    title: string,
    numerical: string
}
const NumericalItem = (props: INumericalItem) => {
    const {title, numerical} = props;
    return (
        <Fragment>
            <div className={"card no-shadow rm-border bg-transparent widget-chart text-start"}>
                <div className={"icon-wrapper rounded-circle"}>
                    <div className={"icon-wrapper-bg opacity-10 bg-warning"}/>
                    <i className={"lnr-list text-dark opacity-8"}/>
                </div>
                <div className={"widget-chart-content"}>
                    <div className={"widget-subheading"}>
                        {title}
                    </div>
                    <div className={"widget-numbers"}>{numerical}</div>
                </div>
            </div>
            <div className={"divider m-9 d-md-none d-sm-block"}/>
        </Fragment>
    )
}

export default NumericalItem;