import React, {Fragment, useState} from 'react';
import cx from "classnames";
import {useSelector} from "react-redux";
import {RootState} from "./hooks";
import AppMain from "@layout/main/AppMain";
import {useResizeDetector} from "react-resize-detector";


function App() {
    let colorScheme = useSelector((state: RootState) => state.theme.colorScheme);
    let enableFixedHeader = useSelector((state: RootState) => state.theme.enableFixedHeader);
    let enableClosedSidebar = useSelector((state: RootState) => state.theme.enableClosedSidebar);
    let enableFixedSidebar = useSelector((state: RootState) => state.theme.enableFixedSidebar);
    let enableFixedFooter = useSelector((state: RootState) => state.theme.enableFixedFooter);
    let enableMobileMenu = useSelector((state: RootState) => state.theme.enableMobileMenu);
    let closedSmallerSidebar = useState(false);
    let enablePageTabsAlt = useSelector((state: RootState) => state.theme.enablePageTabsAlt);
    const {width} = useResizeDetector();
  return (
          <Fragment>
              <div
                  className={cx(
                      "app-container app-theme-" + colorScheme,
                      { "fixed-header": enableFixedHeader },
                      { "fixed-sidebar": enableFixedSidebar || (width || 0) < 1250 },
                      { "fixed-footer": enableFixedFooter },
                      { "closed-sidebar": enableClosedSidebar || (width || 0) < 1250 },
                      { "closed-sidebar-mobile": closedSmallerSidebar || (width || 0) < 1250 },
                      { "sidebar-mobile-open": enableMobileMenu },
                      { "body-tabs-shadow-btn": enablePageTabsAlt }
                  )}
              >
                  <AppMain />
              </div>
          </Fragment>
  );
}

export default App;
