import {Fragment} from "react";
import Theme from "@layout/options/Theme";
import Header from "@layout/header/Header";
import {config} from "@config/config";
import Sidebar from "@layout/sidebar/Sidebar";
import {Route, Routes} from "react-router-dom";
import SearchPage from "@pages/search/Search";
import Management from "@pages/management/Management";

const Pages = () => {

    return (
        <Fragment>
            <Theme/>
            <Header/>
            <div className={"app-main"}>
                {config.serverType === "solution" || config.serverType === "prod" ? <Sidebar/> : <> </>}
                <div className={"app-main__outer"}>
                    <div className={"app-main__inner"}>
                        <Routes>
                            <Route path={"/search/*"} element={<SearchPage/>}/>
                            <Route path={"/management/*"} element={<Management/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Pages;