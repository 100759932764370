import {Fragment} from "react";
import Theme from "@layout/options/Theme";
import Header from "@layout/header/Header";
import Sidebar from "@layout/sidebar/Sidebar";
import Footer from "@layout/footer/Footer";
import DashBoard from "@pages/dashboard/DashBoard";

const DashBoardMain = () => (
    <Fragment>
        <Theme/>
        <Header/>
        <div className={"app-main"}>
            <Sidebar/>
            <div className={"app-main__outer"}>
                <div className={"app-main__inner"}>
                    <DashBoard/>
                </div>
                <div className={"app-wrapper-footer"}>
                    <Footer/>
                </div>
            </div>
        </div>
    </Fragment>
)

export default DashBoardMain;