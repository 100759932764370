import city2 from "@assets/utils/images/dropdown-header/city2.jpg";
import PerfectScrollbar from "react-perfect-scrollbar";
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import avatar1 from "@assets/utils/images/avatars/1.jpg";
import avatar2 from "@assets/utils/images/avatars/2.jpg";
import avatar3 from "@assets/utils/images/avatars/3.jpg";
import {Popover, PopoverBody} from "react-bootstrap";

const PopoverData = () => {
    return (
        <Popover className="popover-custom popover-custom-xl">
            <PopoverBody>
                <div className={"popover show bs-popover-auto"}
                     style={{
                         position: "absolute",
                         inset: "auto auto 0px 0px",
                         transform: "translate3d(93px, -59.5px, 100px)"
                     }}>
                    <div className={"popover-inner"} role={"tooltip"}>
                        <div className="dropdown-menu-header mb-0">
                            <div className="dropdown-menu-header-inner bg-strong-bliss">
                                <div className="menu-header-image opacity-1"
                                     style={{backgroundImage: "url(" + city2 + ")"}}/>
                                <div className="menu-header-content text-white">
                                    <h5 className="menu-header-title">현재 페이지</h5>
                                    <h6 className="menu-header-subtitle">
                                        <b className="text-warning">N </b>
                                        명의 로이더가 함께 만들었습니다.
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="card-tabs-animated card-tabs-animated-inner">
                            <div className="scroll-area-sm">
                                <PerfectScrollbar>
                                    <div className="p-3">
                                        <div className="notifications-box">
                                            <VerticalTimeline animate={false} layout="1-column"
                                                              className="vertical-time-simple vertical-without-time">
                                                <VerticalTimelineElement className="vertical-timeline-item dot-success">
                                                    <h4 className="timeline-title">
                                                        Data Acquisition
                                                        <div className="badge bg-success ms-2">Rookie</div>
                                                        <div className="avatar-wrapper mt-2 avatar-wrapper-overlap">
                                                            <div className="avatar-icon-wrapper avatar-icon-sm">
                                                                <div className="avatar-icon">
                                                                    <img src={avatar1} alt="" title="June Lee"/>
                                                                </div>
                                                            </div>
                                                            <div className="avatar-icon-wrapper avatar-icon-sm">
                                                                <div className="avatar-icon">
                                                                    <img src={avatar2} alt="" title="June Lee"/>
                                                                </div>
                                                            </div>
                                                            <div className="avatar-icon-wrapper avatar-icon-sm">
                                                                <div className="avatar-icon">
                                                                    <img src={avatar3} alt="" title="June Lee"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </h4>
                                                </VerticalTimelineElement>
                                            </VerticalTimeline>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                </div>
            </PopoverBody>
        </Popover>
    )
}

export default PopoverData;