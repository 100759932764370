import cx from "classnames";
import React, {ReactElement} from "react";
import { useAppSelector} from "../../hooks";
import {selectTheme} from "../../hooks/themeOptions";
import TitleComponent from "@layout/main/component/Variation";
interface PageTitleProps {
    heading: string,
    subheading: string,
    icon: string
}
const PageTitle = (props: PageTitleProps) => {
    const {heading, subheading, icon} = props;
    const themeOptions = useAppSelector(selectTheme);

    const randomize = (array: ReactElement[]): ReactElement => {
        return array[Math.floor(Math.random() * array.length)];
    }
    const arr: ReactElement[] = [<TitleComponent/>]

    return (
        <div className="app-page-title">
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className={cx("page-title-icon", {
                        "d-none": !themeOptions.enablePageTitleIcon
                    })}>
                        <i className={icon}/>
                    </div>
                    <div>
                        {heading}
                        <div className={cx("page-title-subheading",{
                            "d-none": !themeOptions.enablePageTitleSubheading
                        })}>
                            {subheading}
                        </div>
                    </div>
                </div>
                <div className={"page-title-actions"}>
                    {randomize(arr)}
                </div>
            </div>
        </div>
    )
}
export default PageTitle;