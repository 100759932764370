import {Fragment, useRef} from "react";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {useAppSelector} from "../../hooks";
import {selectTheme} from "../../hooks/themeOptions";
import cx from "classnames";
import HeaderLogo from "@layout/logo/HeaderLogo";
import SearchBox from "@layout/header/components/SearchBox";
import UserBox from "@layout/header/components/UserBox";

const Header = () => {
    const nodeRef = useRef(null);
    const {
        enableHeaderShadow,
        headerBackgroundColor,
        enableMobileMenuSmall
    } = useAppSelector(selectTheme);
    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component={"div"}
                               nodeRef={nodeRef}
                               className={cx("app-header",headerBackgroundColor,{"header-shadow": enableHeaderShadow})}
                               appear={true}
                               timeout={1500}
                               enter={false}
                               exit={false}>
                    <div>
                        <HeaderLogo/>
                        <div className={cx("app-header__content",{"header-mobile-open": enableMobileMenuSmall})}>
                            <div className={"app-header-left"}>
                                {/*<SearchBox/>*/}
                            </div>
                        </div>
                        <div className={"app-header-right"}>
                                <UserBox/>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default Header;