import {Fragment} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";

interface ConfirmProps {
    message: string;
    setConfirm: (value: boolean) => void;
    nestedModal: boolean;
    setNestedModal: (value: boolean) => void;
}

const Confirm = (props: ConfirmProps) => {
    const {message, setConfirm, nestedModal, setNestedModal} = props;
    return (
        <Fragment>
            <Modal isOpen={nestedModal} toggle={() => setNestedModal(!nestedModal)}>
                <ModalHeader>Confirm</ModalHeader>
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        setConfirm(true);
                        setNestedModal(false);
                    }}>확인</Button>
                    <Button color="secondary" onClick={() => setNestedModal(false)}>취소</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default Confirm;