import {Fragment, useEffect, useState} from "react";
import {SpecialZoomLevel, Viewer, Worker} from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { searchPlugin} from "@react-pdf-viewer/search";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


type PDFViewerProps = {
    path: string;
    keywords?: string[];
    page?: number;
}
const PdfReader = ({path, keywords, page}: PDFViewerProps) => {
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const {jumpToPage} = pageNavigationPluginInstance;
    const searchPluginInstance = searchPlugin({keyword: keywords});
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    useEffect(() => {
        jumpToPage(page ? page : 0);
    }, [page, jumpToPage]);

    useEffect(() => {
        searchPluginInstance.clearHighlights();
    }, [path]);

    return (
        <Fragment>
            <Worker workerUrl={"https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"}>
                <div style={{
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    height: '85vh',
                }}>
                    {path ? (
                        <Viewer fileUrl={path}
                                initialPage={page ? page : 0}
                                plugins={[pageNavigationPluginInstance, searchPluginInstance, defaultLayoutPluginInstance]}
                                defaultScale={SpecialZoomLevel.PageWidth}/>
                    ) : (
                        <div style={{
                            alignItems: 'center',
                            border: '2px rgba(0, 0, 0, .3)',
                            display: 'flex',
                            fontSize: '2rem',
                            height: '100%',
                            justifyContent: 'center',
                            width: '100%',
                        }}>파일을 선택해주세요.</div>
                    )}
                </div>
            </Worker>
        </Fragment>
    )
}

export default PdfReader;
