import {baseInstance} from "../utils/instance";

interface LoginRequest {
    username: string;
    password: string;
}

export const loginUser = async (data: LoginRequest) => {
    try {
        return await baseInstance
            .post("/auth/login", data, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            });
    } catch (error) {
        console.log(error);
    }
}