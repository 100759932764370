import {authInstance} from "@apis/utils/instance";
import Cookies from "js-cookie";
import React from "react";
import avatar1 from "@assets/utils/images/avatars/robot.png";
import axios, {CancelTokenSource} from "axios";

interface SearchVectorNews {
    searchKeyword: string;
    startDate?: string;
    endDate?: string;
    title?: string;
    content?: string;
}

export const searchVectorNews = async (props: SearchVectorNews) => {
    try {
        return await authInstance.get("/vector/e5",{
            params: {
                template_id: "vector_search_e5",
                text: props.searchKeyword,
                date_from: props.startDate,
                date_to: props.endDate,
                title: props.title,
                content: props.content
            },
            headers: {
                Authorization: `Bearer ${Cookies.get("access_token")}`
            }
        });
    }catch(err) {
        console.error(err);
    }
}

export interface PageInfo {
    page: number;
    fileName: string;
    originalFileName: string;
}

export interface ConversationProps {
    message: string;
    pages?: PageInfo[];
    keywords?: string[],
    timestamp: string;
    avatar: string;
    isUser: boolean;
    role: string;
}

export const searchStreamE5 = async (conversations: ConversationProps[],
                                     setConversations: React.Dispatch<React.SetStateAction<any>>,
                                     newQuestion: ConversationProps,
                                     cancelTokenSource: CancelTokenSource) => {
    try {
        const controller = new AbortController();
        cancelTokenSource.token.promise.then(() => controller.abort());
        const response = await fetch('/api/gpt/elastic-docs-e5/stream', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('access_token')}`,
            },
            body: JSON.stringify({
                gpt_model_name: 'gpt-4',
                messages: conversations.concat(newQuestion).map((conv) => ({
                    content: conv.message,
                    role: conv.isUser ? 'user' : 'system',
                })),
            }),
            signal: controller.signal,
        });
        return await setTextConversation(response, setConversations);
    }catch(err) {
        console.error("Error fetching the answer", err);
    }
}

const createMarkup = (botMessage: string) => {
    const formattedMessage = botMessage.replace(/\\n/g, "  \n");
    return { __html: formattedMessage };
}

const setTextConversation = async (response: Response,
                                   setConversations: React.Dispatch<React.SetStateAction<ConversationProps[]>>) => {
    if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let match;
        let botMessage = "";

        while (true) {
            const {value, done} = await reader.read();
            if (done) {
                break;
            }

            const textChunk = decoder.decode(value, {stream: true});
            const regexp = /'value': '([^']*)'/g;
            while ((match = regexp.exec(textChunk)) !== null) {
                if (match[1]){
                    botMessage += match[1];
                }
            }
            // eslint-disable-next-line no-loop-func
            setConversations((prevConversation: ConversationProps[]) => {
                const newConversation = [...prevConversation];
                if (newConversation.length && !newConversation[newConversation.length - 1].isUser){
                    newConversation.pop();
                }
                newConversation.push({
                    message: createMarkup(botMessage).__html,
                    timestamp: new Date().toLocaleDateString(),
                    avatar: avatar1,
                    isUser: false,
                    role: "system"
                });
                return newConversation;
            });
        }
        return true;
    }else {
        console.error("API call failed");
        return false;
    }
}

export const searchResponse = async (conversations: ConversationProps[],
                                     setConversations: React.Dispatch<React.SetStateAction<any>>,
                                     newQuestion: ConversationProps,
                                     cancelTokenSource: CancelTokenSource,
                                     fileName?: string) => {
    try {
        const response = await axios.post("/api/vector/search",
            {
                fileName: fileName,
                messages: conversations.concat(newQuestion).map((conv) => ({
                    content: conv.message,
                    role: conv.isUser ? 'user' : 'system',
                })),
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Cookies.get('access_token')}`,
                },
                cancelToken: cancelTokenSource.token,
            });
        return await setTextConversationResponse(response, setConversations);
    }catch(err: any) {
        if (err.response.status === 404){
            setConversations((prevConversation: any[]) => {
                const newConversation = [...prevConversation];
                newConversation.push({
                    message: "접속이 많이 잠시후 다시 시도해 주세요.",
                    pages: [],
                    keywords: [],
                    timestamp: new Date().toLocaleTimeString(),
                    avatar: avatar1,
                    isUser: false,
                });
                return newConversation;
            });
            return true;
        }
        console.error("Error fetching the answer", err);
    }
}

const setTextConversationResponse = async (response: any,
                                           setConversations: React.Dispatch<React.SetStateAction<any>>) => {
    if (response.status === 200){
        let {answer, references, keywords} = await response.data;
        if (answer.trim() === "제공받은 정보에서 해당 질문의 답변을 찾을 수 없습니다. 답변을 위해서는 추가적인 정보가 필요합니다.".trim()){
            answer = "해당 질문에 대한 문서를 찾지 못해 정확한 답변을 드리기 어려운 점 양해 부탁드립니다.\n\n아래 참조된 링크 중 관련된 문서가 있을 수 있습니다.";
        }
        setConversations((prevConversation: any[]) => {
            const newConversation = [...prevConversation];
            newConversation.push({
                message: createMarkup(answer).__html,
                pages: references.map((pageInfo: {page:number, source:string}) => ({
                    page: pageInfo.page,
                    fileName: pageInfo.source,
                })),
                keywords: keywords,
                timestamp: new Date().toLocaleTimeString(),
                avatar: avatar1,
                isUser: false,
            });
            return newConversation;
        });
        return true;
    }
    return false;
}
