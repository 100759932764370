import {Fragment} from "react";
import {Button, Card, CardBody, CardHeader} from "react-bootstrap";
import {IoIosAnalytics} from "react-icons/io";

const Reference = () => {
    const openInNewTab = (url: string) => {
        window.open(url,'_blank', 'noopener,noreferrer');
    }
    return (
        <Fragment>
            <Card className="mb-3">
                <CardHeader className="card-header-tab">
                    <div className="card-header-title">
                        <i className="header-icon lnr-pointer-up icon-gradient bg-tempting-azure">
                            {" "}
                        </i>
                        Reference
                    </div>
                </CardHeader>
                <CardBody className="text-center d-block p-3">
                    <Button
                        color="primary"
                        className="btn-pill btn-shadow btn-wide fsize-1"
                        size="lg"
                        onClick={() => openInNewTab('https://lloydk.co.kr')}>
                        <span className="me-2 opacity-7">
                            <IoIosAnalytics color="#ffffff" />
                        </span>
                        <span className="me-1">LLOYDK Homepage</span>
                    </Button>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default Reference;