import {Fragment} from "react";
import {Card, CardHeader, Col, Row} from "react-bootstrap";
import NumericalItem from "@pages/dashboard/component/NumericalItem";

const DataStatus = () => {
    return (
        <Fragment>
                <Card className={"mb-3"}>
                    <CardHeader className={"card-header-tab z-index-6"}>
                        <div className={"card-header-title"}>
                            <i className={"header-icon lnr-database icon-gradient bg-tempting-azure"}>
                                {" "}
                            </i>
                            사용 데이터 현황
                        </div>
                    </CardHeader>
                    <Row className={"g-0"}>
                        <Col sm={6} md={4} xl={4}>
                            <NumericalItem title={"전체 데이터 색인량"} numerical={"815 GB"}/>
                        </Col>
                        <Col sm={6} md={4} xl={4}>
                            <NumericalItem title={"전체 인덱스 수"} numerical={"1.3 M"}/>
                        </Col>
                        <Col sm={6} md={4} xl={4}>
                            <NumericalItem title={"백터 데이터량"} numerical={"151 GB"}/>
                        </Col>
                    </Row>
                </Card>
        </Fragment>
    )
}

export default DataStatus;