import sideBar6 from '../assets/utils/images/sidebar/city1.jpg';
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "./index";

interface themeState {
    backgroundColor: string;
    enableBackGroundImage: boolean;
    headerBackgroundColor: string;
    backgroundImage: {};
    backgroundImageOpacity: string;
    enableHeaderShadow: boolean;
    enablePageTabsAlt: boolean;
    enableMobileMenuSmall: boolean;
    enableFixedHeader: boolean;
    enableMobileMenu: boolean;
    enableFixedFooter: boolean;
    enableFixedSidebar: boolean;
    enableClosedSidebar: boolean;
    enableSidebarShadow: boolean;
    enablePageTitleIcon: boolean;
    enablePageTitleSubheading: boolean;
    colorScheme: string;
}

const initialState: themeState = {
    backgroundColor: '',
    headerBackgroundColor: '',
    enableBackGroundImage: false,
    backgroundImage: sideBar6,
    backgroundImageOpacity: 'opacity-06',
    enableMobileMenu: false,
    enableMobileMenuSmall: false,
    enableHeaderShadow: true,
    enableFixedHeader: true,
    enableFixedFooter: true,
    enableFixedSidebar: true,
    enableClosedSidebar: false,
    enableSidebarShadow: true,
    enablePageTitleIcon: true,
    enablePageTitleSubheading: true,
    enablePageTabsAlt: true,
    colorScheme: 'white',
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setBackgroundColor: (state, action: PayloadAction<string>) => {
            state.backgroundColor = action.payload;
        },
        setHeaderBackgroundColor: (state, action: PayloadAction<string>) => {
            state.headerBackgroundColor = action.payload;
        },
        setEnableBackgroundImage: (state, action: PayloadAction<boolean>) => {
            state.enableBackGroundImage = action.payload;
        },
        setBackgroundImage: (state, action: PayloadAction<string>) => {
            state.backgroundImage = action.payload;
        },
        setBackgroundImageOpacity: (state, action: PayloadAction<string>) => {
            state.backgroundImageOpacity = action.payload;
        },
        setEnableMobileMenu: (state, action: PayloadAction<boolean>) => {
            state.enableMobileMenu = action.payload;
        },
        setEnableMobileMenuSmall: (state, action: PayloadAction<boolean>) => {
            state.enableMobileMenuSmall = action.payload;
        },
        setEnableHeaderShadow: (state, action: PayloadAction<boolean>) => {
            state.enableHeaderShadow = action.payload;
        },
        setEnableFixedHeader: (state, action: PayloadAction<boolean>) => {
            state.enableFixedHeader = action.payload;
        },
        setEnableFixedFooter: (state, action: PayloadAction<boolean>) => {
            state.enableFixedFooter = action.payload;
        },
        setEnableFixedSidebar: (state, action: PayloadAction<boolean>) => {
            state.enableFixedSidebar = action.payload;
        },
        setEnableClosedSidebar: (state, action: PayloadAction<boolean>) => {
            state.enableClosedSidebar = action.payload;
        },
        setEnablePageTitleIcon: (state, action: PayloadAction<boolean>) => {
            state.enablePageTitleIcon = action.payload;
        },
        setEnablePageTitleSubheading: (state, action: PayloadAction<boolean>) => {
            state.enablePageTitleSubheading = action.payload;
        },
        setEnablePageTabsAlt: (state, action: PayloadAction<boolean>) => {
            state.enablePageTabsAlt = action.payload;
        },
        setColorScheme: (state, action: PayloadAction<string>) => {
            state.colorScheme = action.payload;
        },
    }
});

export const themeActions = themeSlice.actions;
export const selectTheme = (state: RootState) => state.theme;
export default themeSlice.reducer;