import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, CardBody } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCheck, faCopy, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ConversationProps, PageInfo } from "@apis/api/search";
import { selectedFile } from "@layout/foldertree/FolderTree";

interface Props {
    conversations: ConversationProps[];
    selectedPage?: { fileName: string, page: number } | null;
    setSelectedPage?: React.Dispatch<React.SetStateAction<{ fileName: string; page: number } | null>>;
    setSelectedFile?: React.Dispatch<React.SetStateAction<selectedFile>>;
    setPage?: React.Dispatch<React.SetStateAction<number>>;
    keywords?: string[];
    setKeywords?: React.Dispatch<React.SetStateAction<string[]>>;
}

const ChatTextArea = (props: Props) => {
    const [buttonAnimation, setButtonAnimation] = useState<{ [key: string]: boolean }>({});
    const scrollRef = useRef<HTMLDivElement>(null);
    const [copyStatus, setCopyStatus] = useState<{ [key: string]: boolean }>({});
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [props.conversations]);

    const changePage = (page: PageInfo, keywords: string) => {
        if (props.setPage) {
            props.setPage(page.page);
            props.setSelectedFile && props.setSelectedFile({ name: page.originalFileName, fileName: page.fileName });
        }
        props.setSelectedPage && props.setSelectedPage({ fileName: page.fileName, page: page.page });
        props.setKeywords && props.setKeywords(keywords.split(','));
    }

    const copyToClipboard = async (text: string, index: number) => {
        try {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(text);
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                document.body.removeChild(textArea);
            }
            setCopyStatus((prev) => ({ ...prev, [index]: true }));
            setTimeout(() => {
                setCopyStatus((prev) => ({ ...prev, [index]: false }));
            }, 1000);
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };

    const handleAnimation = (key: string) => {
        setButtonAnimation((prev) => ({ ...prev, [key]: true }));
        setTimeout(() => {
            setButtonAnimation((prev) => ({ ...prev, [key]: false }));
        }, 500);
    }

    return (
        <Fragment>
            <Card className="main-card mb-3 pre" style={{ height: "70vh" }}>
                <CardBody style={{ overflow: "scroll", msOverflowStyle: 'none', scrollbarWidth: 'none', fontFamily: 'Noto Sans KR', fontSize: 'medium' }}>
                    <div style={{ textAlign: "center" }}>
                        <h5>Chatbot</h5>
                    </div>
                    <hr />
                    {props.conversations.map((conv, index) =>
                        conv.isUser ? (
                                conv.message !== "" ? <Fragment key={`user-fragment-${index}`}>
                                        <div key={`user-${index}`} className={"chat-box-wrapper chat-box-wrapper-right float-end"}>
                                            <div>
                                                <div className={"chat-box"} style={{ display: "block", backgroundColor: "#FFEA89" }}>
                                                    {conv.message}
                                                </div>
                                                <small className={"opacity-6"}>
                                                    <FontAwesomeIcon icon={faCalendarAlt} className={"me-1"} />
                                                    {conv.timestamp}
                                                </small>
                                            </div>
                                        </div>
                                    </Fragment> : null
                        ) : (
                            <Fragment key={`bot-fragment-${index}`}>
                                <div key={`bot-${index}`} className={"chat-box-wrapper"}>
                                    <div>
                                        <div className="avatar-icon-wrapper ms-1">
                                            <div className="avatar-icon avatar-icon-lg rounded">
                                                <img className={"rounded-circle"} style={{ backgroundColor: "black" }} src={conv.avatar} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"chat-box"} style={{ display: "inline-block", marginRight: "5%", maxWidth: "95%"}} >
                                            <ReactMarkdown children={conv.message}

                                                           components={{
                                                               code(props) {
                                                                   const { children, className, node, ...rest } = props;
                                                                   const match = /language-(\w+)/.exec(className || '');
                                                                   return match ? (
                                                                       <SyntaxHighlighter
                                                                           {...rest}
                                                                           PreTag={"div"}
                                                                           children={String(children).replace(/\n$/, '')}
                                                                           language={match[1]}
                                                                           style={dark}
                                                                           ref={""} />
                                                                   ) : (
                                                                       <code {...rest} className={className}>
                                                                           {children}
                                                                       </code>
                                                                   )
                                                               }
                                                           }} />
                                        </div>
                                        <small className={"opacity-6"}>
                                            <FontAwesomeIcon icon={faCalendarAlt} className={"me-1"} />
                                            {conv.timestamp}
                                            <Button variant="link" size="sm"
                                                    className={buttonAnimation[`copy-${index}`] ? 'animated-button' : ''}
                                                    onClick={() => {
                                                        copyToClipboard(conv.message, index);
                                                        handleAnimation(`copy-${index}`);
                                                    }}>
                                                <FontAwesomeIcon icon={copyStatus[index] ? faCheck : faCopy} />
                                            </Button>
                                            <Button variant="link" size="sm">
                                                <FontAwesomeIcon icon={faThumbsUp} />
                                            </Button>
                                            <Button variant="link" size="sm">
                                                <FontAwesomeIcon icon={faThumbsDown} />
                                            </Button>
                                            <input type="hidden" id={`keywords-${index}`}
                                                   value={conv.keywords?.join(",")} />
                                        </small>
                                        {conv.pages && conv.pages.map((pageInfo, pageIndex) => (
                                            <div key={`${index}-${pageIndex}`}>
                                                <Button
                                                    className={props.selectedPage?.fileName === pageInfo.fileName && props.selectedPage?.page === pageInfo.page ? "btn-primary" : "btn-light btn-outline-primary"}
                                                    style={{ margin: "0.2rem" }}
                                                    onClick={() => {
                                                        const keywords = (document.getElementById(`keywords-${index}`) as HTMLInputElement).value;
                                                        changePage(pageInfo, keywords);
                                                    }}>
                                                    {pageInfo.fileName} {pageInfo.page + 1} 페이지
                                                </Button><br />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    <div ref={scrollRef} />
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default ChatTextArea;
