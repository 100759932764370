import {Fragment, useEffect, useRef, useState} from "react";
import {TableColumn} from "react-data-table-component";
import {Button, Card, CardBody, Col, Row} from "react-bootstrap";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import DataTable from "react-data-table-component";
import {getUserList} from "@apis/api/management";
import Confirm from "@layout/popup/Confirm";
import MemberCreate from "@pages/management/member/components/MemberCreate";
import MemberUpdate from "@pages/management/member/components/MemberUpdate";


type DataRow = {
    username: string;
    name: string;
    email: string;
    perm_manager: boolean;
}





const MemberList = () => {
    const [showModalCreate, setShowModalCreate] = useState(false);
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [containerData, setContainerData] = useState({name: '', username: '', email: '', perm_manager: false});
    const [userDataList, setUserDataList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRows, setTotalRows] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [reload, setReload] = useState(false);
    const nodeRef = useRef(null);
    const Columns : TableColumn<DataRow>[] = [
        {
            name: "id",
            selector: row => row.username
        },
        {
            name: "이름",
            selector: row => row.name
        },
        {
            name: "이메일",
            selector: row => row.email
        },
        {
            name: "권한",
            selector: row => {
                if (row.perm_manager){
                    return '관리자';
                }else {
                    return '회원'
                }
            }
        },
        {
            name: "관리",
            cell: row => (
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button style={{whiteSpace: 'nowrap'}} onClick={() => {
                        setContainerData(row);
                        setShowModalUpdate(true);
                    }}>수정</Button>
                    <Button style={{whiteSpace: 'nowrap'}} onClick={() => {
                        setContainerData(row);
                        setNestedModal(true);
                    }}>삭제</Button></div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ]

    const controlNestedModal = () => {
        setNestedModal(!nestedModal);
    }
    const fetchUserList = async (page:number) => {
        setLoading(true);
        await getUserList(page, perPage)
            .then(data => {
                setPageNumber(page);
                setUserDataList(data.data);
                setTotalRows(data.total_count);
            });
        setLoading(false);
    }
    const handlePageChange = (page:number) => {
        fetchUserList(page);
    }

    const handlePerRowsChange = async (page:number, newPerPage:number) => {
        setLoading(true);
        await getUserList(page, newPerPage)
            .then(data => {
                setUserDataList(data.data);
                setPerPage(newPerPage);
            });
        setLoading(false);
    }

    useEffect(() => {
        fetchUserList(pageNumber);
        setReload(false);
    },[reload]);
    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component={"div"}
                               nodeRef={nodeRef}
                               classNames={"TabsAnimation"}
                               appear={true}
                               timeout={1500}
                               enter={false}
                               exit={false}>
                    <div>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Card className={"main-card mb-3"}>
                                    <CardBody>
                                        <Button style={{float: 'right'}}
                                                onClick={() => setShowModalCreate(true)}>추가</Button>
                                        <DataTable data={userDataList}
                                                   columns={Columns}
                                                   progressPending={loading}
                                                   pagination
                                                   paginationServer
                                                   paginationTotalRows={totalRows}
                                                   onChangeRowsPerPage={handlePerRowsChange}
                                                   onChangePage={handlePageChange}
                                                   />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <MemberCreate showModel={showModalCreate}
                                      setShowModel={setShowModalCreate}
                                      setReload={setReload}/>
                        <MemberUpdate containerData={containerData}
                                      showModal={showModalUpdate}
                                      setShowModal={setShowModalUpdate}
                                      setReload={setReload}/>
                        <Confirm message={"삭제하시겠습니까?"}
                                 setConfirm={setConfirm}
                                 nestedModal={nestedModal}
                                 setNestedModal={setNestedModal}/>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default MemberList;