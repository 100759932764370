import {Fragment} from "react";
import {Card, CardBody, FormGroup, Modal, ModalBody} from "react-bootstrap";

export interface ContainerData {
    username: string;
    name: string;
    email: string;
    perm_manager: boolean;
}

interface MemberUpdateProps {
    containerData: ContainerData;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    setReload: (value: boolean) => void;
}

const MemberUpdate = (props: MemberUpdateProps) => {
    return (
        <Fragment>
            <Modal show={props.showModal} onHide={() => props.setShowModal(!props.showModal)}>
                <ModalBody>
                    <Card className={"mb-5"}>
                        <CardBody>
                            <FormGroup>

                            </FormGroup>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default MemberUpdate;