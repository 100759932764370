import {Fragment, useRef} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {selectTheme, themeActions} from "../../hooks/themeOptions";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import cx from "classnames";
import HeaderLogo from "@layout/logo/HeaderLogo";
import PerfectScrollbar from "react-perfect-scrollbar";
import VerticalNavWrapper from "@layout/nav/VerticalNavWrapper";


const Sidebar = () => {
    const nodeRef = useRef(null);
    const {
        enableMobileMenu,
        backgroundColor,
        enableSidebarShadow,
        backgroundImage,
        enableBackGroundImage,
        backgroundImageOpacity
    } = useAppSelector(selectTheme);
    const dispatch = useAppDispatch();
    const toggleMobileSidebar = () => {
        dispatch(themeActions.setEnableMobileMenu(!enableMobileMenu));
    }
    return (
        <Fragment>
            <div className={"sidebar-mobile-overlay"} onClick={toggleMobileSidebar}/>
            <TransitionGroup>
                <CSSTransition component={"div"}
                               nodeRef={nodeRef}
                               className={cx("app-sidebar", backgroundColor,{"sidebar-shadow": enableSidebarShadow})}
                               appear={true}
                               enter={false}
                               exit={false}
                               timeout={500}>
                    <div>
                        <HeaderLogo/>
                        <PerfectScrollbar>
                            <div className={"app-sidebar__inner"}>
                                <VerticalNavWrapper/>
                            </div>
                        </PerfectScrollbar>
                        <div className={cx("app-sidebar-bg", backgroundImageOpacity)}
                             style={{backgroundImage: enableBackGroundImage ? "url("+ backgroundImage + ")" : undefined}}/>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default Sidebar;