import React, {Fragment, useRef, useState} from "react";
import {ConversationProps, searchStreamE5} from "@apis/api/search";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import ChatTextArea from "@pages/search/chatbot/component/ChatTextArea";
import InputTextArea from "@pages/search/chatbot/component/InputTextArea";
import {Col, Row} from "react-bootstrap";

const ElasticDocsGuide = () => {
    const [conversations, setConversations] = useState<ConversationProps[]>([]);
    const nodeRef = useRef(null);
    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component="div"
                               nodeRef={nodeRef}
                               classNames={"TabsAnimation"}
                               appear={true}
                               timeout={1500}
                               enter={false}
                               exit={false}>
                    <div ref={nodeRef}>
                        <Row>
                            <Col lg="2" sm={0}></Col>
                            <Col lg="8" sm="12">
                                <ChatTextArea conversations={conversations}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="2" sm={0}></Col>
                            <Col lg="8" sm="12">
                                <InputTextArea conversations={conversations}
                                               setConversations={setConversations}
                                               searchFunction={searchStreamE5}/>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default ElasticDocsGuide;