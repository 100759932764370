import {Fragment, useState} from "react";
import {Button, OverlayTrigger} from "react-bootstrap";
import PopoverData from "@layout/footer/components/Popover";



const FooterDots = () => {
    const [popOverOpen, setPopOverOpen] = useState(false);

    return (
        <Fragment>
            <div className="footer-dots">
                <OverlayTrigger overlay={PopoverData} trigger={"click"} placement={"top-start"}>
                    <Button onClick={() => setPopOverOpen(!popOverOpen)} className="dot-btn-wrapper bg-white border-white" id="PopoverFooter">
                        <i className="dot-btn-icon lnr-bullhorn icon-gradient bg-mean-fruit"/>
                        <div className="badge badge-dot badge-abs badge-dot-sm bg-danger">
                            Notifications
                        </div>
                    </Button>
                </OverlayTrigger>

                <div className="dots-separator"/>
            </div>
        </Fragment>
    )
}
export default FooterDots;