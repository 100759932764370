import {Fragment} from "react";
import {Route, Routes} from "react-router-dom";
import News from "@pages/search/vector/nlp/News";


const VectorSearch = () => {
    return (
        <Fragment>
            <Routes>
                <Route path={"news"} element={<News/>}/>
            </Routes>
        </Fragment>
    )
}

export default VectorSearch;