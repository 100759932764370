export const json = {
    name: 'pdf',
    isFolder: true,
    items: [
        {
            name: '한화생명 시그니처 암보험 3.0 (비갱신형) 무배당.pdf',
            fileName: '한화생명 시그니처 암보험 3.0 (비갱신형) 무배당.pdf',
            isFolder: false,
        },
        {
            name: '한화 시그니처 여성 건강보험2.0 무배당2404.pdf',
            fileName: '한화 시그니처 여성 건강보험2.0 무배당2404.pdf',
            isFolder: false,
        }
    ]
}